import BackendAccess from '@/services/BackendAccess'

class DatasetBackendAccess {
  constructor() {
    this.access = new BackendAccess()
  }

  searchDatasets(
    limit,
    offset,
    searchWord,
    datasetState,
    sortBy,
    reverseOrder,
    callback,
    errorCallback
  ) {
    const limitFilter = `?limit=${limit}`
    const offsetFilter = `&offset=${offset}`
    const pasFilter = searchWord.length > 0 ? `&search=${searchWord}` : ''
    const stateFilter = `&state=${datasetState}`
    const order = reverseOrder ? '-' : ''
    /* id -attribute added to get paging work TPASPKT-367 */
    const orderingFilter = `&ordering=${order}${sortBy},id`
    const path = `datasets/${limitFilter}${offsetFilter}${stateFilter}${pasFilter}${orderingFilter}`
    return this.access.get(path, callback, errorCallback)
  }

  async getDataset(datasetId) {
    const path = `${this.access.basePath}datasets/${datasetId}/`
    try {
      const response = await this.access.service.get(path, { silenceAlertForStatuses: [404] })

      return response.data
    } catch (e) {
      if (e.response.status === 404) {
        return null
      }

      throw e
    }
  }

  async changeAgreement(datasetId, newAgreement) {
    const path = `${this.access.basePath}datasets/${datasetId}/agreement`
    return await this.access.service.post(path, this.access.encodeForm(newAgreement))
  }

  resetDataset(datasetId, reason, callback, errorCallback) {
    const path = `datasets/${datasetId}/reset`
    const postData = { message: reason }
    return this.access.post(path, postData, callback, errorCallback)
  }

  async checkFileCount(datasetId) {
    const path = `${this.access.basePath}files/file-count`
    const response = await this.access.service.get(path, { params: { "dataset-identifier": datasetId } })
    const data = response.data

    return {
      fileCount: data.file_count,
      softLimit: data.soft_limit,
      hardLimit: data.hard_limit
    }
  }

  async confirmDataset(datasetId, reason) {
    const path = `${this.access.basePath}datasets/${datasetId}/confirm`
    return await this.access.service.post(path, this.access.encodeForm({ message: reason }))
  }

  async proposeDataset(datasetId, reason) {
    const path = `${this.access.basePath}datasets/${datasetId}/propose`
    return await this.access.service.post(path, this.access.encodeForm({ message: reason }))
  }

  async generateMetadata(datasetId) {
    const path = `${this.access.basePath}/datasets/${datasetId}/generate-metadata`
    return await this.access.service.post(path)
  }

  async rejectDataset(datasetId, reason, callback, errorCallback) {
    const path = `datasets/${datasetId}/reject`
    const postData = { message: reason }
    return await this.access.post(path, postData, callback, errorCallback)
  }

  async acceptDataset(datasetId) {
    const path = `${this.access.basePath}datasets/${datasetId}/preserve`
    return await this.access.service.post(path)
  }

  async createDip(datasetId) {
    const path = `${this.access.basePath}dips/create`
    const response = await this.access.service.post(path, null, { params: { "dataset-identifier": datasetId } })
    return response.data
  }

  async getDipStatus(datasetId) {
    const path = `${this.access.basePath}dips/status`
    const response = await this.access.service.get(path, { params: { "dataset-identifier": datasetId }, silenceAlertForStatuses: [404] })
    return response.data
  }

  getDipDownloadUrl(datasetId) {
    return `${this.access.basePath}dips/download?dataset-identifier=${datasetId}`
  }
}

export default DatasetBackendAccess
