import BackendAccess from '@/services/BackendAccess'

class FileBackendAccess {
  constructor() {
    this.access = new BackendAccess()
  }

  /**
   * Retrieve metadata for a file in dataset. The metadata is retrieved from
   * Metax and may be located in upload-rest-api or IDA.
   *
   * Returns an object with the fields:
   * {
   *   id: integer,
   *   identifier: string,
   *   fileName: string,
   *   filePath: string,
   *
   *   fileSize: integer,
   *
   *   created: Date,
   *   modified: Date,
   *   fileUploaded: Date,
   *   fileModified: Date,
   *
   *   checksum: {algorithm, checked, value}
   *   fileStorage: {id, identifier}
   *   fileCharacteristics: {file_format, format_version, ...}
   *   fileCharacteristicsExtension: arbitrary nested object
   * }
   */
  async getDatasetFile(datasetId, fileId) {
    const path = `${this.access.basePath}/files/file/${fileId}`
    const response = await this.access.service.get(
      path, { params: { "dataset-identifier": datasetId } }
    )
    const data = response.data
    const result = {
      identifier: data.identifier,
      fileName: data.file_name,
      filePath: data.file_path,
      fileSize: data.byte_size,
      checksum: data.checksum,
      useCategory: data.use_category,

      // These fields are reproduced as-is, snake_case and all.
      // This makes it easier to convert them to a natural form
      // (eg. "foo_and_bar" -> "Foo and bar").
      fileCharacteristics: data.file_characteristics,
    }
    return result
  }

  async getDatasetDirectory(datasetId, dirPath) {
    const path = `${this.access.basePath}/files/directory`
    return await this.access.service.get(path, { params: { "dataset-identifier": datasetId, "path": dirPath} })
  }
}

export default FileBackendAccess
